var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg_screen", attrs: { id: "book_screen" } }, [
    _c(
      "div",
      { staticClass: "book_container" },
      [
        _c(
          "div",
          {
            class: [
              "font" + _vm.fontSize,
              {
                chapter_end: _vm.isChapterEnd,
                game_end: _vm.isGameEnd,
              },
            ],
            attrs: { id: "book" },
          },
          [
            _c("div"),
            _c("BookPage", {
              attrs: {
                "sequence-text": _vm.gameplay.introTextSequence.sequenceText,
              },
            }),
            _vm._l(_vm.softSkills, function (softSkill) {
              return [
                _c("BookPage", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isChapterEnd,
                      expression: "!isChapterEnd",
                    },
                  ],
                  key: softSkill.id + "-1",
                  attrs: {
                    "sequence-text": softSkill.introTextSequence.sequenceText,
                  },
                }),
                _c("BookPage", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isChapterEnd,
                      expression: "isChapterEnd",
                    },
                  ],
                  key: softSkill.id + "-2",
                  attrs: {
                    "sequence-text": softSkill.outroTextSequence.sequenceText,
                  },
                }),
              ]
            }),
            _c("BookPage", {
              attrs: {
                "sequence-text": _vm.gameplay.outroTextSequence.sequenceText,
              },
            }),
          ],
          2
        ),
        _c("NarrationButtons", {
          staticClass: "book_narration_actions",
          on: { continue: _vm.passBookChapter },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    id="book_screen"
    class="bg_screen"
  >
    <div class="book_container">
      <div
        id="book"
        :class="[
          'font' + fontSize,
          {
            'chapter_end': isChapterEnd,
            'game_end': isGameEnd
          }
        ]"
      >
        <div />

        <BookPage :sequence-text="gameplay.introTextSequence.sequenceText" />

        <template v-for="softSkill in softSkills">
          <BookPage
            v-show="!isChapterEnd"
            :key="softSkill.id + '-1'"
            :sequence-text="softSkill.introTextSequence.sequenceText"
          />

          <BookPage
            v-show="isChapterEnd"
            :key="softSkill.id + '-2'"
            :sequence-text="softSkill.outroTextSequence.sequenceText"
          />
        </template>

        <BookPage :sequence-text="gameplay.outroTextSequence.sequenceText" />
      </div>

      <NarrationButtons
        class="book_narration_actions"
        @continue="passBookChapter"
      />
    </div>
  </div>
</template>
<script>
import BookPage from '@/components/BookPage'
import NarrationButtons from '@/components/NarrationButtons'

export default {
  name: 'Book',
  components: {
    BookPage,
    NarrationButtons
  },
  data() {
    return {
      bookPosition: { width: 0, height: 0 },
      header: this.$parent.$refs.header
    }
  },
  computed: {
    gameplay() {
      return this.$store.getters.gameplay
    },

    avatar() {
      return this.$store.getters.avatar
    },

    hasBookIntro() {
      return !this.$store.getters.hasVideoIntro
    },

    fontSize() {
      return this.$store.getters.fontSize
    },

    softSkills() {
      return this.$store.getters.gameplay.softSkills
    },

    isChapterEnd() {
      return this.$parent.isChapterEnd
    },

    isGameEnd() {
      return this.$parent.isGameEnd
    }
  },
  created() {
    let pathAac = ''
    let pathOgg = ''

    if (this.softSkillIdx === 0 && this.hasBookIntro) {
      pathAac = this.gameplay.introTextSequence.narrationPathAac
      pathOgg = this.gameplay.introTextSequence.narrationPathOgg
    }
    else if (this.isGameEnd) {
      pathAac = this.gameplay.outroTextSequence.narrationPathAac
      pathOgg = this.gameplay.outroTextSequence.narrationPathOgg
    }
    else {
      Object.entries(this.softSkills).some(([softSkillIndex, softSkill]) => {
        if (this.softSkillIdx === parseInt(softSkillIndex)) {
          pathAac = this.isChapterEnd ? softSkill.outroTextSequence.narrationPathAac : softSkill.introTextSequence.narrationPathAac
          pathOgg = this.isChapterEnd ? softSkill.outroTextSequence.narrationPathOgg : softSkill.introTextSequence.narrationPathOgg
          return true
        }
      })
    }

    this.$store.commit('setNarrationFinished', false)
    this.$store.commit('setNarrationPathAac', pathAac)
    this.$store.commit('setNarrationPathOgg', pathOgg)

    window.addEventListener('resize', this.handleBookResize)
  },
  mounted() {
    this.header.allowMuteNarration = true
    this.header.allowFontEdit = true
    this.$parent.questionPlaying = false

    const bookWrapper = jQuery('#book')
    this.bookPosition = this.calcBookPosition(bookWrapper)

    const flipSound = !this.header.mutedBgSound
    const bookOptions = {
      height: this.bookPosition.height,
      width: this.bookPosition.width,
      flipSound: flipSound,
      closable: true,
      centeredWhenClosed: false,
      hardcovers: false,
      toolbar: '',
      responsiveHandleWidth: 50,
      container: false,
      containerPadding: '20px',
      startPage: 0,
      gutterShadow: false,
      pageNumbers: false
    }

    bookWrapper.find('.text_container').before('<div></div>')
    bookWrapper.wowBook(bookOptions)

    const book = jQuery.wowBook('#book')
    const pageNum =
      (this.hasBookIntro ? 2 : 2) +
      (this.varExists(this.avatar) ? 2 : 0) +
      (this.softSkillIdx * 4) +
      (this.isChapterEnd ? 2 : 0)

    book.showPage(0, false)
    this.preparePage(bookWrapper, pageNum)

    setTimeout(() => {
      book.gotoPage(pageNum)
    }, 100)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleBookResize)
  },
  methods: {
    preparePage(bookWrapper, pageNum) {
      const targetPage = bookWrapper.find('.wowbook-page-content:eq(' + pageNum + ')')

      targetPage.find('.page_txt').show()
      targetPage.mCustomScrollbar({ theme: 'dark-thin' })
      targetPage.find('p:first').html(function(i, html) {
        return html.replace(/^(.)/g, '<span class="firstcharacter">$1</span>')
      })

      if (pageNum !== 36) {
        this.startNarration(true)
      }
    },

    passBookChapter() {
      var bookWrapper = jQuery('#book')
      var book = jQuery.wowBook('#book')
      var pageNum = bookWrapper.find('.wowbook-page').length - 1
      this.preparePage(bookWrapper, pageNum)
      book.gotoPage(pageNum)

      this.$parent.routeGame()
    },

    handleBookResize() {
      const bookPosition = this.calcBookPosition(jQuery('#book'))
      const book = jQuery.wowBook('#book')
      book.setDimensions(bookPosition.width, bookPosition.height)
    },

    calcBookPosition(bookWrapper) {
      bookWrapper
        .removeClass('highRatio')
        .removeClass('variedRatio')
        .removeClass('fullscreen')

      const screenWidth = document.body.clientWidth
      const screenHeight = window.innerHeight
      const screenRatio = screenWidth / screenHeight

      let heightMultiplier = screenRatio / 2.315
      if (screenRatio < 1.7) {
        heightMultiplier *= 1.78 / screenRatio
        bookWrapper.addClass('variedRatio')
      }

      if (screenRatio <= 1.8) {
        bookWrapper.addClass('fullscreen')
      }

      let height = 588
      if (screenWidth > 1024 || screenWidth < 999) {
        height = Math.ceil(screenHeight * heightMultiplier)
      }

      let width = 1060
      if (screenWidth < 1024) {
        width = screenWidth * 2
      }
      return { width: width, height: height }
    }
  }
}
</script>

<template>
  <div class="text_container">
    <div class="page_txt">
      <div v-html="sequenceText" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookPage',
  props: {
    sequenceText: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  #book .page_txt {
    display: none;
  }
</style>
